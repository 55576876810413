import {
  AssigneeListItem,
  assigneePickerUnassignedContent,
  html,
  icon,
  render,
  xIcon
} from '@cumu/shared';
import { loc_clearSelection } from '@cumu/strings';
import { target } from '@github/catalyst';
import RemoteInputElement from '@github/remote-input-element';
import { getLocale } from '../locale';
import { controller } from './controller';
import { dispatchChange } from './util';

@controller('assignee-picker')
export class AssigneePickerElement extends HTMLElement {
  @target declare userInput: HTMLInputElement;
  @target declare organizationInput: HTMLInputElement;
  @target declare summary: HTMLElement;
  @target declare remoteInput: RemoteInputElement;
  @target declare suggestions: HTMLElement;
  @target declare contractFormGroupContainer: HTMLElement;

  get value(): AssigneeListItem | null {
    const raw = this.summary
      .querySelector('[data-value]')!
      .getAttribute('data-value')!;
    return JSON.parse(raw) as AssigneeListItem;
  }

  focusMenu() {
    this.remoteInput.input!.focus();
  }

  select() {
    const value = this.value;
    const organization_id = value?.organization_id ?? '';
    const user_id = value?.user_id ?? '';
    let change = false;
    if (this.organizationInput.value !== organization_id) {
      change = true;
      if (organization_id && this.organizationInput.value === '') {
        this.insertClearSelection();
      }
      this.organizationInput.value = organization_id;
      dispatchChange(this.organizationInput);

      if (this.contractFormGroupContainer) {
        const template = organization_id
          ? this.suggestions.querySelector(
              `[data-contracts-for="${organization_id}"]`
            )
          : this.suggestions.querySelector(
              '[data-create-organization-contracts]'
            );
        if (template instanceof HTMLTemplateElement) {
          this.contractFormGroupContainer.innerHTML = '';
          this.contractFormGroupContainer.append(template.content);
        }
      }
    }
    if (this.userInput.value !== user_id) {
      change = true;
      this.userInput.value = user_id;
      dispatchChange(this.userInput);
    }
    if (this.remoteInput.input?.value) {
      this.remoteInput.input.value = '';
      dispatchChange(this.remoteInput.input);
    }
    if (change) {
      this.dispatchEvent(
        new CustomEvent('assignee-change', { bubbles: true, detail: value })
      );
    }
  }

  remoteSuccess() {
    if (this.organizationInput.value) {
      this.insertClearSelection();
    }
  }

  insertClearSelection() {
    if (this.suggestions.querySelector('[data-clear-selection]')) {
      return;
    }
    const locale = getLocale();
    this.suggestions.insertAdjacentHTML(
      'afterbegin',
      render(
        html`<button
          type="button"
          role="menuitem"
          class="SelectMenu-item"
          data-clear-selection
        >
          ${icon(xIcon, 'mr-1')} ${loc_clearSelection[locale]}
          <div hidden data-menu-button-contents>
            ${assigneePickerUnassignedContent()}
          </div>
        </button>`
      )
    );
  }
}

declare global {
  interface Window {
    AssigneePickerElement: typeof AssigneePickerElement;
  }
  interface HTMLElementTagNameMap {
    'assignee-picker': AssigneePickerElement;
  }
}
