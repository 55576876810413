import {
  defaultLocale,
  loc_multipleFilesFormat,
  loc_multipleMedicationsFormat,
  loc_multipleSignaturesFormat,
  loc_no,
  loc_oneFile,
  loc_oneMedication,
  loc_oneSignature,
  loc_unanswered,
  loc_yes,
  Locale
} from '@cumu/strings';
import { userDateFormat, userUTCDateFormat } from '../date';
import { maskToArray } from '../mask';
import { currencyFormat, numberFormat } from '../number';
import { html } from '../template';
import { AddressCategory } from './address';
import { Expression } from './expression';

export type EntityTypeName =
  | 'activity'
  | 'activity_definition'
  | 'person'
  | 'form'
  | 'task'
  | 'organization'
  | 'report'
  | 'user_organization'
  | 'organization'
  | 'task_definition'
  | 'form_design'
  | 'organization_project';

export function typeToSlug(type: EntityTypeName) {
  switch (type) {
    case 'activity':
      return 'activities';
    case 'activity_definition':
      return 'activity-definitions';
    case 'person':
      return 'people';
    case 'form':
      return 'activities';
    case 'task':
      return 'activities';
    case 'organization':
      return 'organizations';
    case 'report':
      return 'reports';
    case 'user_organization':
      return 'members';
    case 'task_definition':
      return 'task-definitions';
    case 'form_design':
      return 'form-designs';
    case 'organization_project': // not supported
    default:
      throw new Error(`Unexpected type "${type}`);
  }
}

export function renderCalculatedValue(
  property: Property,
  value: any,
  locale = defaultLocale
) {
  if (value === null || value === undefined || value === '') {
    return html`<em class="color-fg-muted">${loc_unanswered[locale]}</em>`;
  }
  if (property.type === 'int' && property.choice_type === 'single') {
    const choice = property.choices?.find(c => c.choice_id === value);
    return choice?.label ?? value;
  }
  switch (property.type) {
    case 'bit':
      return value ? loc_yes[locale] : loc_no[locale];
    case 'currency':
      return typeof value === 'number' ? currencyFormat.format(value) : value;
    case 'decimal':
      return numberFormat.format(value);
    case 'int':
      return value >= 1900 || value < 2100 // don't use commas when the int might be a year
        ? value
        : numberFormat.format(value);
    case 'date':
      return userUTCDateFormat.format(new Date(value));
    default:
      return value;
  }
}

export function getPropertyDisplayValue({
  locale,
  property,
  value,
  showScore,
  activities
}: {
  locale: Locale;
  property: Property;
  value: any;
  showScore: boolean;
  activities: { activity_id: string; title: string }[];
}): string {
  if (value === undefined || value === null) {
    return loc_unanswered[locale];
  }
  if (property.calculated) {
    return renderCalculatedValue(property, value, locale);
  }
  switch (property.choice_type) {
    case 'single': {
      const choices = property.choices;
      if (!choices) {
        throw new Error('Property.choices expected.');
      }
      const choice = choices.find(c => c.choice_id === value);
      if (!choice) {
        throw new Error(
          `Choice not found for choice id ${value} in property "${property.label}" (${property.property_id}).`
        );
      }
      if (showScore && choices.some(c => c.score !== 0)) {
        return `${choice.label} (score ${choice.score})`;
      }
      return choice.label;
    }
    case 'multiple': {
      const choices = property.choices;
      if (!choices) {
        throw new Error('Property.choices expected.');
      }
      const selected = (Array.isArray(value) ? value : maskToArray(value))
        .map(id => choices.find(c => c.choice_id === id)!)
        .filter(x => x);
      const labels = selected.map(c => c.label).join(', ');
      if (showScore && choices.some(c => c.score !== 0)) {
        return `${labels} (score ${selected.reduce((acc, c) => acc + c.score, 0)})`;
      }
      return labels;
    }
  }
  switch (property.type) {
    case 'date':
      return userDateFormat.format(new Date(value));
    case 'decimal':
      return numberFormat.format(value);
    case 'int':
      return value >= 1900 || value < 2100 // don't use commas when the int might be a year
        ? value.toString()
        : numberFormat.format(value);
    case 'varchar100':
    case 'varcharmax':
      return value;
    case 'bit':
      return value ? loc_yes[locale] : loc_no[locale];
    case 'currency':
      return currencyFormat.format(value);
    case 'activity':
      const activity = activities.find(a => a.activity_id === value);
      return activity?.title ?? value;
    case 'attachment':
      return value === 1
        ? loc_oneFile[locale]
        : loc_multipleFilesFormat[locale](value);
    case 'signature':
      return value === 1
        ? loc_oneSignature[locale]
        : loc_multipleSignaturesFormat[locale](value);
    case 'medications':
      return value === 1
        ? loc_oneMedication[locale]
        : loc_multipleMedicationsFormat[locale](value);
    default:
      throw new Error(
        `getPropertyDisplayValue not implemented for type: "${property.type}"; calculated: "${property.calculated}"; choice_type: "${property.choice_type}";`
      );
  }
}

export interface Choice {
  choice_id: number;
  label: string;
  prompt: string;
  exclusive: boolean;
  score: number;
}

export type PropertyType =
  | 'uniqueidentifier' // todo: remove?
  | 'activity'
  | 'attachment'
  | 'signature'
  | 'medications'
  | 'varchar100'
  | 'varcharmax'
  | 'int'
  | 'bit'
  | 'currency'
  | 'decimal'
  | 'date'
  | 'addresses' // todo: remove
  | 'phones' // todo: remove
  | 'emails'; // todo: remove

export type PropertyChoiceType =
  | 'none'
  | 'single'
  | 'multiple'
  | 'big-single'
  | 'big-multiple';

export const enum PropertyFormat {
  None = 0,
  Email = 1,
  FEIN = 2,
  Phone = 3,
  SSN = 4,
  Zip = 5,
  Zip4 = 6
}

export const propertyFormats = [
  { value: PropertyFormat.None, label: 'None' },
  { value: PropertyFormat.Email, label: 'Email' },
  { value: PropertyFormat.FEIN, label: 'FEIN' },
  { value: PropertyFormat.Phone, label: 'Phone' },
  { value: PropertyFormat.SSN, label: 'SSN' },
  { value: PropertyFormat.Zip, label: 'Zip' },
  { value: PropertyFormat.Zip4, label: 'Zip+4' }
];

export const propertyFormatInfo: Record<
  PropertyFormat,
  | { min: number; max: number }
  | { min: number; max: number; mask: string; regex: RegExp }
  | null
> = {
  [PropertyFormat.None]: null,
  [PropertyFormat.Email]: { min: 4, max: 100 },
  [PropertyFormat.FEIN]: {
    min: 10,
    max: 10,
    mask: '##-#######',
    regex: /^\d{2}-\d{7}$/
  },
  [PropertyFormat.Phone]: {
    min: 14,
    max: 14
    // mask: '(###) ###-####',
    // regex: /\(\d{3}\) \d{3}-\d{4}$/
  },
  [PropertyFormat.SSN]: {
    min: 11,
    max: 11,
    mask: '###-##-####',
    regex: /\d{3}-\d{2}-\d{4}$/
  },
  [PropertyFormat.Zip]: {
    min: 5,
    max: 5,
    mask: '#####',
    regex: /^\d{5}$/
  },
  [PropertyFormat.Zip4]: {
    min: 5,
    max: 9,
    mask: '#####-####',
    regex: /^\d{5}(?:-\d{4})?$/
  }
};

export interface Property {
  property_id: number;
  entity_type: EntityTypeName; // activity | task
  type: PropertyType; // todo: remove uniqueidentifier, addresses, phones, emails
  choice_type: PropertyChoiceType;
  format: PropertyFormat;
  label: string;
  prompt: string;
  min: number;
  max: number;
  calculated: boolean;
  expression: Expression | null;
  report_filter: boolean; // remove
  report_sort: boolean; // remove
  report_column: boolean; // remove
  response: boolean; // remove
  choices?: Choice[];
}

export interface AdminProperty extends Property {
  temp_id: string;
  name: string; // remove
  form_field: boolean; // remove
  create_user_id: string;
  create_datetime: string;
  update_user_id: string;
  update_datetime?: string;
}

export const wellKnownProperties = {
  person: {
    last_name: 1,
    first_name: 2,
    middle_name: 3,
    addresses: 4,
    phones: 5,
    emails: 6,
    gender: 7,
    gender_identity: 1990,
    sexual_orientation: 1009,
    race: 1010,
    ethnicity: 1011,
    marital_status: 1085,
    primary_language: 1012,
    dob: 8,
    external_id: 106,
    full_name: 45,
    ssn: 1055,
    veteran_id: 1056,
    medicaid_id: 1092,
    agingis_id: 2705,

    primary_physical: {
      address: 48,
      city: 50,
      region_abbr: 51,
      subregion: 52,
      postal_code: 53,
      street_address: 82,
      sub_address: 83,
      postal_code_ext: 84,
      is_precise: 164
    },

    primary_mailing: {
      address: 49,
      city: 75,
      region_abbr: 76,
      subregion: 77,
      postal_code: 78,
      street_address: 79,
      sub_address: 80,
      postal_code_ext: 81,
      is_precise: 165
    },

    primary_phone: {
      formatted: 85,
      type: 86,
      number: 87,
      extension: 88
    },

    primary_email: {
      formatted: 89,
      type: 90,
      email: 91
    },

    create_date: 54,
    create_organization_id: 55,
    create_user_id: 56,
    update_date: 57,
    update_organization_id: 58,
    update_user_id: 59,
    user_id: 2171
  },
  user_organization: {
    family_name: 168,
    given_name: 169,
    login: 170,
    email: 171,
    projects: 172,
    organization_id: 173,
    role_id: 342,
    primary_phone: {
      formatted: 174,
      type: 175,
      number: 176,
      extension: 177
    }
  },
  activity: {
    activity_definition_id: 243,
    create_date: 244,
    create_organization_id: 245,
    create_user_id: 246,
    start_date: 247,
    end_date: 248,
    status: 251,
    status_date: 252,
    update_date: 253,
    due_date: 2647,
    update_organization_id: 254,
    update_user_id: 255,
    project_id: 257,
    assignee_id: 258,
    assignee_user_id: 259,
    assignee_organization_id: 260,
    assignment_datetime: 1673,
    contract_id: 432,
    type: 261,
    sctid: 262,
    create_dow: 263,
    subscribed: 264,
    comment_create_user_id: 265,
    comment_mentioned_user_id: 266,
    title: 276,
    primary_physical: {
      address: 267,
      city: 268,
      region_abbr: 269,
      subregion: 270,
      postal_code: 271,
      street_address: 272,
      sub_address: 273,
      postal_code_ext: 274,
      is_precise: 275
    },
    primary_phone: {
      formatted: 2535,
      type: 2536,
      number: 2537,
      extension: 2538
    },
    primary_email: {
      formatted: 2539,
      type: 2540,
      email: 2541
    },
    assignee_organization_primary_physical: {
      address: 277,
      city: 278,
      region_abbr: 279,
      subregion: 280,
      postal_code: 281,
      street_address: 282,
      sub_address: 283,
      postal_code_ext: 284,
      is_precise: 285
    },
    assignee_organization_primary_email: {
      formatted: 1086,
      type: 1087,
      email: 1088
    },
    assignee_role_id: 286,
    create_organization_primary_physical: {
      address: 287,
      city: 288,
      region_abbr: 289,
      subregion: 290,
      postal_code: 291,
      street_address: 292,
      sub_address: 293,
      postal_code_ext: 294,
      is_precise: 295
    },
    create_organization_primary_email: {
      formatted: 1089,
      type: 1090,
      email: 1091
    },
    attachment_count: 408,
    consent_granted: 1303
  },
  organization_project: {
    organization_id: 324,
    project_id: 325,
    project_join_date: 326,
    is_active: 327,
    is_admin: 341,
    classification: 2010,
    fein: 2011,
    primary_phone: {
      formatted: 328,
      type: 329,
      number: 330,
      extension: 331
    },
    primary_physical: {
      address: 332,
      city: 333,
      region_abbr: 334,
      subregion: 335,
      postal_code: 336,
      street_address: 337,
      sub_address: 338,
      postal_code_ext: 339,
      is_precise: 340
    }
  }
};

export const addressMapLookup: Record<
  number,
  typeof wellKnownProperties.activity.primary_physical
> = {};
Object.values(wellKnownProperties.activity.primary_physical).forEach(
  x => (addressMapLookup[x] = wellKnownProperties.activity.primary_physical)
);
Object.values(
  wellKnownProperties.activity.assignee_organization_primary_physical
).forEach(
  x =>
    (addressMapLookup[x] =
      wellKnownProperties.activity.assignee_organization_primary_physical)
);
Object.values(
  wellKnownProperties.activity.create_organization_primary_physical
).forEach(
  x =>
    (addressMapLookup[x] =
      wellKnownProperties.activity.create_organization_primary_physical)
);
Object.values(wellKnownProperties.person.primary_physical).forEach(
  x => (addressMapLookup[x] = wellKnownProperties.person.primary_physical)
);
Object.values(wellKnownProperties.person.primary_mailing).forEach(
  x => (addressMapLookup[x] = wellKnownProperties.person.primary_mailing)
);

export interface ProjectPropertyUtilization {
  project_id: string;
  project_login: string;
  activity_definitions?: {
    activity_definition_id: string;
    title: string;
    create_organization_login: string;
  }[];
  response_count: number;
  used_in_routing_rules: boolean;
}

export interface PropertyChoiceUtilization {
  choice_id: number;
  label: string;
  count: number;
}

export const propertyIdToAddressCategory: Record<number, AddressCategory> = {
  [wellKnownProperties.activity.primary_physical.city]: 'City',
  [wellKnownProperties.activity.primary_physical.postal_code]: 'Primary Postal',
  [wellKnownProperties.activity.primary_physical.region_abbr]: 'Region',
  [wellKnownProperties.activity.primary_physical.subregion]: 'Subregion',
  [wellKnownProperties.activity.assignee_organization_primary_physical.city]:
    'City',
  [wellKnownProperties.activity.assignee_organization_primary_physical
    .postal_code]: 'Primary Postal',
  [wellKnownProperties.activity.assignee_organization_primary_physical
    .region_abbr]: 'Region',
  [wellKnownProperties.activity.assignee_organization_primary_physical
    .subregion]: 'Subregion',
  [wellKnownProperties.activity.create_organization_primary_physical.city]:
    'City',
  [wellKnownProperties.activity.create_organization_primary_physical
    .postal_code]: 'Primary Postal',
  [wellKnownProperties.activity.create_organization_primary_physical
    .region_abbr]: 'Region',
  [wellKnownProperties.activity.create_organization_primary_physical.subregion]:
    'Subregion',
  [wellKnownProperties.person.primary_physical.city]: 'City',
  [wellKnownProperties.person.primary_physical.postal_code]: 'Primary Postal',
  [wellKnownProperties.person.primary_physical.region_abbr]: 'Region',
  [wellKnownProperties.person.primary_physical.subregion]: 'Subregion',
  [wellKnownProperties.organization_project.primary_physical.city]: 'City',
  [wellKnownProperties.organization_project.primary_physical.postal_code]:
    'Primary Postal',
  [wellKnownProperties.organization_project.primary_physical.region_abbr]:
    'Region',
  [wellKnownProperties.organization_project.primary_physical.subregion]:
    'Subregion'
};

export type AdminPropertyDisplayType =
  | 'Boolean'
  | 'Date'
  | 'Integer'
  | 'Text'
  | 'Long text'
  | 'Currency'
  | 'Decimal'
  | 'Activity field'
  | 'Attachment'
  | 'Signature'
  | 'Medications'
  | 'Single choice'
  | 'Multiple choice'
  | 'Single choice (dynamic)'
  | 'Multiple choice (dynamic)';

export interface AdminPropertyInfo {
  display_type: AdminPropertyDisplayType;
  type: PropertyType;
  choice_type: PropertyChoiceType;
  format: PropertyFormat;
  report_column: boolean;
  report_filter: boolean;
  report_sort: boolean;
  uses_choices: boolean;
  response: boolean;
  min: number;
  max: number;
  defaultMax: number;
}

export const adminPropertyTypes: {
  [propertyType in PropertyType]?: {
    [choiceType in PropertyChoiceType]?: AdminPropertyInfo;
  };
} = {
  activity: {
    'big-single': {
      display_type: 'Activity field',
      type: 'activity',
      choice_type: 'big-single',
      format: PropertyFormat.None,
      report_column: true,
      report_filter: true,
      report_sort: false,
      uses_choices: false,
      response: true,
      min: 0,
      max: 0,
      defaultMax: 0
    }
  },
  attachment: {
    none: {
      display_type: 'Attachment',
      type: 'attachment',
      choice_type: 'none',
      format: PropertyFormat.None,
      report_column: true,
      report_filter: true,
      report_sort: false,
      uses_choices: false,
      response: true,
      min: 0,
      max: 0,
      defaultMax: 0
    }
  },
  signature: {
    none: {
      display_type: 'Signature',
      type: 'signature',
      choice_type: 'none',
      format: PropertyFormat.None,
      report_column: true,
      report_filter: true,
      report_sort: false,
      uses_choices: false,
      response: true,
      min: 0,
      max: 0,
      defaultMax: 0
    }
  },
  medications: {
    none: {
      display_type: 'Medications',
      type: 'medications',
      choice_type: 'none',
      format: PropertyFormat.None,
      report_column: false,
      report_filter: true,
      report_sort: false,
      uses_choices: false,
      response: true,
      min: 0,
      max: 0,
      defaultMax: 0
    }
  },
  varchar100: {
    none: {
      display_type: 'Text',
      type: 'varchar100',
      choice_type: 'none',
      format: PropertyFormat.None,
      report_column: true,
      report_filter: true,
      report_sort: false,
      uses_choices: false,
      response: true,
      min: 0,
      max: 100,
      defaultMax: 50
    }
  },
  varcharmax: {
    none: {
      display_type: 'Long text',
      type: 'varcharmax',
      choice_type: 'none',
      format: PropertyFormat.None,
      report_column: true,
      report_filter: true,
      report_sort: false,
      uses_choices: false,
      response: true,
      min: 0,
      max: 10000,
      defaultMax: 200
    }
  },
  int: {
    none: {
      display_type: 'Integer',
      type: 'int',
      choice_type: 'none',
      format: PropertyFormat.None,
      report_column: true,
      report_filter: true,
      report_sort: false,
      uses_choices: false,
      response: true,
      min: 0,
      max: 1000000,
      defaultMax: 100
    },
    single: {
      display_type: 'Single choice',
      type: 'int',
      choice_type: 'single',
      format: PropertyFormat.None,
      report_column: true,
      report_filter: true,
      report_sort: false,
      uses_choices: true,
      response: true,
      min: 0,
      max: 0,
      defaultMax: 0
    },
    multiple: {
      display_type: 'Multiple choice',
      type: 'int',
      choice_type: 'multiple',
      format: PropertyFormat.None,
      report_column: true,
      report_filter: true,
      report_sort: false,
      uses_choices: true,
      response: true,
      min: 0,
      max: 0,
      defaultMax: 0
    }
  },
  bit: {
    none: {
      display_type: 'Boolean',
      type: 'bit',
      choice_type: 'none',
      format: PropertyFormat.None,
      report_column: true,
      report_filter: true,
      report_sort: false,
      uses_choices: false,
      response: true,
      min: 0,
      max: 0,
      defaultMax: 0
    }
  },
  currency: {
    none: {
      display_type: 'Currency',
      type: 'currency',
      choice_type: 'none',
      format: PropertyFormat.None,
      report_column: true,
      report_filter: true,
      report_sort: false,
      uses_choices: false,
      response: true,
      min: 0,
      max: 1000000,
      defaultMax: 10000
    }
  },
  date: {
    none: {
      display_type: 'Date',
      type: 'date',
      choice_type: 'none',
      format: PropertyFormat.None,
      report_column: true,
      report_filter: true,
      report_sort: false,
      uses_choices: false,
      response: true,
      min: 0,
      max: 0,
      defaultMax: 0
    }
  },
  decimal: {
    none: {
      display_type: 'Decimal',
      type: 'decimal',
      choice_type: 'none',
      format: PropertyFormat.None,
      report_column: true,
      report_filter: true,
      report_sort: false,
      uses_choices: false,
      response: true,
      min: 0,
      max: 1000000,
      defaultMax: 10000
    }
  }
};

export const adminPropertyTypesByDisplayName = Object.values(
  adminPropertyTypes
).reduce(
  (acc, pt) => {
    for (const ct of Object.values(pt)) {
      acc[ct.display_type] = ct;
    }
    return acc;
  },
  {} as Record<AdminPropertyDisplayType, AdminPropertyInfo>
);
