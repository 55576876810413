import {
  ActivityDefinition,
  ActivityDefinitionElement,
  ActivityExpressionScope,
  ActivityExpressionStandardPropertyValues,
  activityStatuses,
  addDays,
  AreaOfPersistentUnemploymentLookupItem,
  AssigneeListItem,
  CreateActivityArgs,
  DependencyError,
  evaluateActivityExpression,
  html,
  icon,
  isActivityEndDateRequired,
  keyBy,
  maskToArray,
  pageActivityStatusLabelTemplate,
  parseForm,
  parseUserDate,
  progressRingIconContent,
  Property,
  PublicCreateActivityArgs,
  render,
  renderCalculatedFieldDependencyList,
  renderCalculatedValue,
  RucaLookupItem,
  UpdateActivityArgs,
  userDateFormat,
  walkActivityElements
} from '@cumu/shared';
import { loc_fields } from '@cumu/strings';
import { attr as cattr, target, targets } from '@github/catalyst';
import TabContainerElement from '@github/tab-container-element';
import { allFormData } from '../forms/utilities';
import {
  FormValidationErrorEventDetails,
  validateForm
} from '../forms/validation';
import { getLocale } from '../locale';
import type { AssigneePickerElement } from './assignee-picker';
import { controller } from './controller';
import { dispatchChange, focusSomething } from './util';

const locale = getLocale();

@controller('activity-controller')
export default class ActivityControllerElement extends HTMLElement {
  // history = new Map<number, Promise<ResponseHistoryItem[]>>();
  @target declare form: HTMLFormElement;
  @target declare elementsContainer: HTMLDivElement;
  @target declare elementsSummaryContainer: HTMLDivElement;
  @target declare creationFields: HTMLDivElement | null;
  @target declare timelineContainer: HTMLDivElement;
  @target declare editCommentTemplate: HTMLTemplateElement;
  @target declare subscribersSection: HTMLDivElement;
  @target declare attachmentsSection: HTMLDivElement;
  @target declare eventQuestionnairesSection: HTMLDivElement;
  @targets declare needsActivityId: HTMLElement[];
  @target declare addAnotherContent: HTMLElement;
  @target declare assigneePicker: AssigneePickerElement;
  @target declare assigneePrintContainer: HTMLElement;
  @target declare addChildPlaceholder: HTMLElement;
  @targets declare headingNavLinks: HTMLAnchorElement[];
  @targets declare printCommentsItems: HTMLElement[];
  @cattr declare organization_login: string;
  @cattr declare organization_id: string;
  @cattr declare subject_person_id: string;
  @cattr declare activity_id: string;
  @cattr declare status_assignment_src: string;
  assignmentRuleTimeout = 0;
  inputTimeout = 0;
  disposeClickHandler = () => {};

  connectedCallback() {
    if (location.hash === '#:print:') {
      history.replaceState(undefined, '', location.pathname + location.search);
      print();
    }
    resolveFieldLinks(this.form);
    const handler = (event: Event) => {
      const behavior =
        event.target instanceof Element &&
        event.target
          .closest('[activity-behavior]')
          ?.getAttribute('activity-behavior');
      switch (behavior) {
        case 'edit-details':
          this.editElements(event);
          return;
        case 'print-summary':
        case 'print-summary-with-comments':
        case 'print-details': {
          const { origin, pathname, search } = location;
          const actual = pathname.endsWith('/details');
          const requested = behavior === 'print-details';
          if (actual === requested) {
            this.printCommentsItems.forEach(el =>
              el.classList.toggle(
                'd-print-none',
                behavior !== 'print-summary-with-comments'
              )
            );
            print();
            addEventListener('afterprint', () =>
              this.printCommentsItems.forEach(el =>
                el.classList.add('d-print-none')
              )
            );
          } else {
            const requestedPathname = actual
              ? pathname.substr(0, pathname.length - '/details'.length)
              : pathname + '/details';
            location.href = `${origin}${requestedPathname}${search}#:print:`;
          }
          return;
        }
        default:
          if (behavior) {
            throw new Error(`Unexpected activity-behavior "${behavior}".`);
          }
      }
    };
    addEventListener('click', handler);
    this.disposeClickHandler = () => this.removeEventListener('click', handler);
  }

  disconnectedCallback() {
    this.disposeClickHandler();
    this.disposeClickHandler = () => {};
  }

  get newCommentTextArea() {
    return this.form.elements.namedItem('comment_body') as HTMLTextAreaElement;
  }

  get formBehavior() {
    return this.form.querySelector('form-behavior');
  }

  handleValidationError(event: CustomEvent<FormValidationErrorEventDetails>) {
    if (
      event.detail.group.hasAttribute('form-element') &&
      event.detail.group.classList.contains('form-element--skipped')
    ) {
      event.preventDefault();
    } else {
      this.editElements();
    }
  }

  handleServerValidationError() {
    this.editElements();
  }

  editElements = (event?: Event, elementToFocus?: Element | null) => {
    if (this.elementsContainer?.hidden) {
      event?.preventDefault();
      this.elementsContainer.hidden = false;
      this.elementsSummaryContainer.remove(); // todo: fix duplicate heading ids
      if (elementToFocus) {
        elementToFocus.scrollIntoView({ block: 'center' });
      }
      focusSomething(elementToFocus ?? this.elementsContainer);
      history.pushState(undefined, '', location.pathname + '/details');
      document
        .querySelector('.UnderlineNav-item[aria-current="page"]')
        ?.setAttribute('aria-current', 'false');
      document
        .querySelector('.UnderlineNav-item[href$="/details"]')
        ?.setAttribute('aria-current', 'page');
    }
  };

  focusElement(event: Event) {
    const idish =
      event.target instanceof Element &&
      event.target.getAttribute('element-identifier');
    const el = this.querySelector(
      `[form-element="${idish}"],[name="${idish}"],[name^="${idish}:"]`
    );
    this.editElements(event, el);
  }

  async activitySaved(event: CustomEvent<Response>) {
    // Reset comment textarea and its preview tab.
    this.newCommentTextArea.value = '';
    dispatchChange(this.newCommentTextArea);
    this.querySelectorAll<TabContainerElement>(
      'markdown-editor tab-container'
    ).forEach(el => {
      if (el.tabIndex! === 0) {
        el.selectTab(0);
      }
    });

    // Switch to PUT mode body
    if (this.creationFields) {
      this.activity_id = event.detail.headers.get('activity_id')!;
      this.creationFields.remove();

      for (const el of [
        ...this.needsActivityId,
        ...document.querySelectorAll<HTMLElement>(
          '#sticky-organization-header [href*="__ACTIVITY_ID__"]'
        )
      ]) {
        if (el.matches('[src*="__ACTIVITY_ID__"]')) {
          el.setAttribute(
            'src',
            el.getAttribute('src')!.replace('__ACTIVITY_ID__', this.activity_id)
          );
        }
        if (el.matches('[href*="__ACTIVITY_ID__"]')) {
          el.setAttribute(
            'href',
            el
              .getAttribute('href')!
              .replace('__ACTIVITY_ID__', this.activity_id)
          );
        }
      }
    }

    const html = await event.detail.text();
    this.timelineContainer.innerHTML = html;
    const subscribersContent =
      this.timelineContainer.querySelector<HTMLTemplateElement>(
        '[id="subscribers-content"]'
      );
    if (subscribersContent) {
      this.subscribersSection.replaceWith(subscribersContent.content);
    }
    const attachmentsContent =
      this.timelineContainer.querySelector<HTMLTemplateElement>(
        '[id="attachments-content"]'
      );
    if (attachmentsContent) {
      this.attachmentsSection.replaceWith(attachmentsContent.content);
    }
    const eventQuestionnairesContent =
      this.timelineContainer.querySelector<HTMLTemplateElement>(
        '[id="event-questionnaires-content"]'
      );
    if (eventQuestionnairesContent) {
      this.eventQuestionnairesSection?.replaceWith(
        eventQuestionnairesContent.content
      );
    }
    const tabNavContent =
      this.timelineContainer.querySelector<HTMLTemplateElement>(
        '[id="activity-tabnav-content"]'
      );
    if (tabNavContent) {
      document
        .getElementById('header-tabnav')
        ?.replaceWith(tabNavContent.content);
    }

    const addChildContent =
      this.timelineContainer.querySelector<HTMLTemplateElement>(
        '[id="add-child-content"]'
      );
    if (addChildContent) {
      this.addChildPlaceholder?.replaceWith(addChildContent.content);
    }

    this.timelineContainer
      .querySelectorAll<HTMLTemplateElement>('[id^="attachment-question-"]')
      .forEach(template => {
        this.querySelector(
          `[form-element="${template.id.substr('attachment-question-'.length)}"]`
        )?.replaceWith(template.content);
      });

    this.addAnotherContent?.removeAttribute('hidden');

    // Previous steps will have artifically dirtied the form... accept those changes.
    this.form.querySelector('form-behavior')?.acceptChanges();
  }

  statusFocus({ target }: Event) {
    // clear status id from hash after it receives focus.
    if (target instanceof Element && target.id === location.hash.substr(1)) {
      history.replaceState(undefined, '', location.pathname + location.search);
    }
  }

  recommendationValueInputFocused({ target }: Event) {
    target instanceof Element &&
      target
        .closest('.form-group')!
        .querySelector<HTMLElement>('button,a')!
        .focus();
  }

  assigneeChanged({ detail: a }: CustomEvent<AssigneeListItem>) {
    document.getElementById('assignee-print-container')!.innerHTML = render(
      html`<span class="text-bold">${a.organization_name}</span>${a.user_name
          ? ` / ${a.user_name} - ${a.user_email}`
          : null}`
    );
  }

  async subscriptionUpdated(event: CustomEvent<Response>) {
    const html = await event.detail.text();
    const template = document.createElement('template');
    template.innerHTML = html;
    this.subscribersSection.replaceWith(template.content);
  }

  async editComment(event: Event) {
    const item = this.getTimelineItem(event);
    if (!item) {
      return;
    }
    const markdownContent = item.querySelector('.markdown-body')!;
    const activity_comment_id = markdownContent.getAttribute(
      'activity_comment_id'
    );
    const markdown = markdownContent.getAttribute('source-markdown') ?? '';
    const fragment = this.editCommentTemplate.content.cloneNode(
      true
    ) as DocumentFragment;
    const form = fragment.firstElementChild as HTMLFormElement;
    form.action = `/api/organizations/${this.organization_id}/activities/${this.activity_id}/comments/${activity_comment_id}?_method=PUT`;
    const bodyTextArea = form.elements.namedItem('body') as HTMLTextAreaElement;
    bodyTextArea.value = markdown;
    item.append(fragment);
    item.querySelector<HTMLElement>('.TimelineItem-body')!.hidden = true;
    await new Promise(resolve => setTimeout(resolve));
    'scrollIntoViewIfNeeded' in bodyTextArea &&
    typeof bodyTextArea.scrollIntoViewIfNeeded === 'function'
      ? bodyTextArea.scrollIntoViewIfNeeded()
      : bodyTextArea.scrollIntoView({ block: 'center', behavior: 'smooth' });
    bodyTextArea.focus();
  }

  keyDown(event: KeyboardEvent) {
    // When a user presses tab after editing an number/text input and the
    // subsequent field becomes visible due to conditional logic,
    // focus will skip the newly visible field.
    // The following code forces evaluation of the form state early to circumvent the issue.
    if (
      event.key === 'Tab' &&
      document.activeElement instanceof HTMLInputElement &&
      document.activeElement.type === 'number'
    ) {
      this.handleChange(event);
    }
  }

  editCommentKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      event.preventDefault();
      this.cancelCommentEdit(event);
    }
  }

  cancelCommentEdit(event: Event) {
    const item = this.getTimelineItem(event);
    if (!item) {
      return;
    }
    const form =
      event.target instanceof Element && event.target.closest('form');
    if (form) {
      form.remove();
    }
    item.querySelector<HTMLElement>('.TimelineItem-body')!.hidden = false;
  }

  async deleteComment(event: Event) {
    const item = this.getTimelineItem(event);
    if (!item) {
      return;
    }
    if (!confirm('Are you sure you want to delete this comment?')) {
      return;
    }
    const markdownContent = item.querySelector('.markdown-body')!;
    const activity_comment_id = markdownContent.getAttribute(
      'activity_comment_id'
    );
    const response = await fetch(
      `/api/organizations/${this.organization_id}/activities/${this.activity_id}/comments/${activity_comment_id}`,
      {
        method: 'DELETE'
      }
    );
    if (response.ok) {
      item.remove();
    }
  }

  async quoteReply(event: Event) {
    const item = this.getTimelineItem(event);
    if (!item) {
      return;
    }
    const markdown =
      item.querySelector('.markdown-body')?.getAttribute('source-markdown') ??
      '';
    const prompt = `[enter your reply here]`;
    const blockquote = `> ${markdown.split(/\r?\n/).join('\n> ')}\n\n`;
    const start = blockquote.length;
    this.newCommentTextArea.value = blockquote + prompt;
    dispatchChange(this.newCommentTextArea);
    await new Promise(resolve => setTimeout(resolve));
    this.newCommentTextArea.scrollIntoView({ behavior: 'smooth' });
    this.newCommentTextArea.focus();
    this.newCommentTextArea.setSelectionRange(
      start,
      this.newCommentTextArea.value.length
    );
  }

  getTimelineItem(event: Event) {
    return (
      event.target instanceof Element &&
      event.target.closest<HTMLElement>('.TimelineItem')
    );
  }

  _elementsAndProperties:
    | {
        activityDefinition: Pick<
          ActivityDefinition,
          'default_status' | 'type' | 'duration_days'
        >;
        elements: ActivityDefinitionElement[];
        propertyMap: Record<number, Property>;
        standardPropertyValues: ActivityExpressionStandardPropertyValues;
        rucaLookup: RucaLookupItem[];
        areaOfPersistentUnemploymentLookup: AreaOfPersistentUnemploymentLookupItem[];
      }
    | undefined;

  getElementsAndProperties(json: string) {
    if (!this._elementsAndProperties) {
      const {
        elements,
        properties,
        activityDefinition,
        standardPropertyValues,
        rucaLookup,
        areaOfPersistentUnemploymentLookup
      } = JSON.parse(json);
      this._elementsAndProperties = {
        activityDefinition,
        elements,
        propertyMap: keyBy(properties, 'property_id'),
        standardPropertyValues,
        rucaLookup,
        areaOfPersistentUnemploymentLookup
      };
    }
    return this._elementsAndProperties;
  }

  handleInput(event: Event) {
    const { target } = event;
    if (
      (target instanceof HTMLInputElement &&
        ['text', 'number'].includes(target.type)) ||
      target instanceof HTMLTextAreaElement
    ) {
      clearTimeout(this.inputTimeout);
      this.inputTimeout = setTimeout(() => this.handleChange(event), 300);
    }
  }

  nextEventIndex = 0;
  log(event: Event, ...args: any[]) {
    if (new URLSearchParams(location.search).has('debug')) {
      const name =
        (event.target instanceof Element &&
          event.target.getAttribute('name')) ||
        '(name missing)';
      (event as any).__log_index__ ??= this.nextEventIndex++;
      const index = (event as any).__log_index__;
      console.info(`Handle ${event.type} #${index} on ${name}`, ...args);
    }
  }

  handleChange(event: Event) {
    this.log(event, 'Enter handleChange');
    try {
      clearTimeout(this.inputTimeout);
      const formData = allFormData(this.form);
      const isModal = this.closest('modal-content');
      const statusChanged =
        event.type === 'change' &&
        event.target instanceof HTMLInputElement &&
        event.target.name === 'status';

      if (statusChanged) {
        const status = event.target.value;
        // defer validation until after questions have been updated with required status.
        clearTimeout(this.assignmentRuleTimeout);
        this.assignmentRuleTimeout = setTimeout(() => {
          validateForm(this.form, 'info');

          this.formBehavior!.setBusy(this.applyAssignmentRule(formData));

          if (!isModal) {
            const statusLabel = document.getElementById(
              'activity-status-label'
            )!;
            statusLabel.insertAdjacentHTML(
              'afterend',
              render(pageActivityStatusLabelTemplate(status, locale))
            );
            statusLabel.remove();
          }
        }, 0);
      }

      const {
        responses = {},
        definition_json,
        status,
        start_date,
        end_date
      } = parseForm<
        | CreateActivityArgs
        | UpdateActivityArgs
        | (PublicCreateActivityArgs & {
            status: undefined;
            start_date: undefined;
            end_date: undefined;
          })
      >(formData);
      const {
        activityDefinition: { type, duration_days },
        elements,
        propertyMap,
        standardPropertyValues,
        rucaLookup,
        areaOfPersistentUnemploymentLookup
      } = this.getElementsAndProperties(definition_json);
      if (status) {
        standardPropertyValues.activity_status = status;
      }
      standardPropertyValues.activity_start_date =
        start_date ?? new Date().toISOString().substr(0, 10);
      standardPropertyValues.activity_end_date = end_date ?? null;
      standardPropertyValues.activity_assignee_organization =
        this.assigneePicker.value?.organization_login ?? null;
      standardPropertyValues.activity_assignee_user =
        this.assigneePicker.value?.user_login ?? null;
      const scope = new ActivityExpressionScope(
        standardPropertyValues,
        responses,
        propertyMap,
        rucaLookup,
        areaOfPersistentUnemploymentLookup
      );

      const startDateInput = this.form.elements.namedItem('start_date:date');
      const endDateInput = this.form.elements.namedItem('end_date:date');
      const dueDateInput = this.form.elements.namedItem('due_date:date');

      // Set end date required on status change.
      // Set end date value based on whether the status is opened or closed.
      if (
        statusChanged &&
        endDateInput instanceof HTMLInputElement &&
        !endDateInput.disabled
      ) {
        const required = isActivityEndDateRequired(
          type,
          status ?? standardPropertyValues.activity_status
        );
        endDateInput.setAttribute('validate-required', String(required));
        const newEndDateValue = required
          ? userDateFormat.format(new Date())
          : '';
        if (!!endDateInput.value !== !!newEndDateValue) {
          endDateInput.classList.remove('element-changed-animation');
          endDateInput.value = newEndDateValue;
          dispatchChange(endDateInput);
          setTimeout(
            () => endDateInput.classList.add('element-changed-animation'),
            0
          );
          this.log(event, 'Exit handleChange - end date changed');
          return; // dispatchChange will trigger handleChange again.
        }
      }

      // if start date or end date changed, and end date is before start date, update the date to match
      if (
        event.type === 'change' &&
        startDateInput instanceof HTMLInputElement &&
        endDateInput instanceof HTMLInputElement &&
        endDateInput.value &&
        // document.activeElement !== startDateInput &&
        // document.activeElement !== endDateInput &&
        (event.target === startDateInput || event.target === endDateInput) &&
        !endDateInput.disabled
      ) {
        const startDate = parseUserDate(startDateInput.value);
        const endDate = parseUserDate(endDateInput.value);
        if (startDate && endDate && startDate > endDate) {
          const input =
            event.target === startDateInput ? endDateInput : startDateInput;
          const value = event.target === startDateInput ? startDate : endDate;
          input.classList.remove('element-changed-animation');
          input.value = userDateFormat.format(value);
          dispatchChange(input);
          setTimeout(() => input.classList.add('element-changed-animation'), 0);
          this.log(event, 'Exit handleChange - start or end date changed');
          return; // dispatchChange will trigger handleChange again.
        }
      }

      // if start date changed, update due date
      if (
        event.type === 'change' &&
        startDateInput instanceof HTMLInputElement &&
        dueDateInput instanceof HTMLInputElement &&
        // document.activeElement !== startDateInput &&
        // document.activeElement !== dueDateInput &&
        event.target === startDateInput &&
        !startDateInput.disabled
      ) {
        const startDate = parseUserDate(startDateInput.value);
        if (startDate) {
          const value = userDateFormat.format(
            addDays(startDate, duration_days)
          );
          if (dueDateInput.value !== value) {
            dueDateInput.classList.remove('element-changed-animation');
            dueDateInput.value = value;
            dispatchChange(dueDateInput);
            setTimeout(
              () => dueDateInput.classList.add('element-changed-animation'),
              0
            );
            this.log(event, 'Exit handleChange - due date changed');
            return; // dispatchChange will trigger handleChange again.
          }
        }
      }

      let visibleProperties = formData.has('title') ? 1 : 0;
      let answeredProperties = formData.get('title') ? 1 : 0;
      let changed: HTMLInputElement | null = null;
      walkActivityElements(
        elements,
        scope,
        (el, visible, currentlyRequired) => {
          if (changed) {
            return;
          }
          if (visible && el.type === 'property') {
            visibleProperties++;
            if ((responses[`p${el.property_id}`] ?? '') !== '') {
              answeredProperties++;
            }
          }
          const element = this.form.querySelector(
            `[form-element="${el.index}"]`
          );
          if (!element) {
            if (el.type === 'recommendation' || el.type === 'event-activity') {
              return;
            }
            throw new Error(
              `Unable to locate element corresponding to "${el.index}" ("${el.type}").`
            );
          }

          const summaryElements = this.querySelectorAll<HTMLElement>(
            `[summary-element="${el.index}"]`
          );

          // show/hide elements based on their visibility expression.
          summaryElements.forEach(el => el.toggleAttribute('hidden', !visible));
          element.classList.toggle('form-element--skipped', !visible);
          if (el.type === 'heading') {
            const navLink = this.headingNavLinks.find(
              x => x.getAttribute('element') === el.index.toString()
            );
            if (navLink) {
              navLink.hidden = !visible;
            }
          }

          // synchronize required state.
          element.setAttribute(
            'validate-required',
            String(currentlyRequired && visible)
          );

          if (el.type !== 'property') {
            return;
          }

          const property = propertyMap[el.property_id!];
          if (!property) {
            throw new Error(
              `Unable to get property with id "${el.property_id}" from property array.`
            );
          }
          const { property_id, type, calculated, expression } = property;

          // clear data from skipped fields.
          if (!visible) {
            if (responses[`p${property_id}`] !== undefined) {
              if (
                responses[`p${property_id}`] !== '' &&
                responses[`p${property_id}`] !== null
              ) {
                this.log(
                  event,
                  `Clearing ${property.label} (${property_id}) - current value ${JSON.stringify(responses[`p${property_id}`])}`
                );
              }
              setActivityElementValue(element, property, undefined);
              delete responses[`p${property_id}`];
            }
            return;
          }

          // update calculated fields.
          if (!calculated || !expression) {
            return;
          }

          let value = evaluateActivityExpression(type, expression, scope);
          if (responses[`p${property_id}`] !== value) {
            this.log(
              event,
              `Calculated field ${property.label} (${property_id}) changed to ${value}`
            );
            changed = setActivityElementValue(element, property, value);
            this.log(event, 'Changed input', changed);
            responses[`p${property_id}`] = value;
            const outputElement = element.querySelector('output')!;
            outputElement.innerHTML = render(
              renderCalculatedValue(property, value)
            );
          } else {
            this.log(
              event,
              `Calculated field ${property.label} (${property_id}) remained ${value}`
            );
          }
          const detailsContent = render(
            renderCalculatedFieldDependencyList(
              getLocale(),
              responses,
              propertyMap,
              element.getAttribute('references')?.split(',').map(Number) ?? []
            )
          );
          const detailsListElement = element.querySelector('details ul')!;
          if (detailsListElement.innerHTML !== detailsContent) {
            detailsListElement.innerHTML = detailsContent;
          }
        }
      );

      if (changed) {
        this.log(
          event,
          `Displatching change for ${(changed as any).getAttribute('name')}`
        );
        dispatchChange(changed);
        return;
      }

      if (!isModal) {
        const progressLabel = document.getElementById(
          'activity-progress-label'
        );
        if (progressLabel) {
          progressLabel.innerHTML = render(
            html`${icon(
              progressRingIconContent(answeredProperties / visibleProperties),
              'f5 color-fg-accent'
            )}
            ${answeredProperties} of ${visibleProperties} ${loc_fields[locale]}`
          );
        }
      }
    } finally {
      this.log(event, 'Exit handleChange');
    }
  }

  applyAssignmentRule = async (body: FormData) => {
    this.assigneePicker.summary.classList.remove('btn-updated');
    const controller = new AbortController();
    setTimeout(() => controller.abort('timeout'), 5000);
    const url = new URL(this.status_assignment_src, location.origin);
    url.searchParams.set(
      'contract_id',
      (this.form.elements.namedItem('contract_id') as HTMLInputElement).value
    );
    const response = await fetch(`${url.pathname}${url.search}`, {
      method: 'POST',
      body,
      signal: controller.signal
    });
    if (!response.ok) {
      throw new DependencyError(response);
    }
    if (response.status !== 200) {
      return;
    }
    const html = await response.text();
    this.assigneePicker.summary.querySelector('[data-menu-button]')!.innerHTML =
      html;
    this.assigneePicker.select();
    this.assigneePicker.summary.classList.add('btn-updated');
    const subscribersTemplate =
      this.assigneePicker.querySelector<HTMLTemplateElement>(
        '#automatic-subscribers'
      )!;
    (this.form.elements.namedItem('subscribers:json') as Element).replaceWith(
      subscribersTemplate.content
    );
    const contractsTemplate =
      this.assigneePicker.querySelector<HTMLTemplateElement>(
        '#assignment-rule-contract'
      )!;
    this.assigneePicker.contractFormGroupContainer.innerHTML = '';
    this.assigneePicker.contractFormGroupContainer.append(
      contractsTemplate.content
    );
  };

  // async updateResponseHistory(
  //   event: Event,
  //   form = this.readForm(),
  //   design = getData<FormDesign>(form.form_design_id)
  // ) {
  //   const element =
  //     event.target instanceof Element && event.target.closest('[form-element]');
  //   if (!element) {
  //     return;
  //   }
  //   const id = element.getAttribute('form-element');
  //   const el = findFormDesignElement(design.elements, el => el.id === id);
  //   if (!el) {
  //     throw new Error(`Unable to find form design element with id "${id}".`);
  //   }
  //   if (el.type !== 'field') {
  //     return;
  //   }
  //   const property = design.properties.find(p => p.name === el.key);
  //   if (!property) {
  //     throw new Error(
  //       `Unable to get property with name "${el.key}" from form design.`
  //     );
  //   }

  //   if (
  //     property.type === 'addresses' ||
  //     property.type === 'phones' ||
  //     property.type === 'emails'
  //   ) {
  //     this.historyCounter.textContent = '0';
  //     this.historyContainer.innerHTML = '';
  //     return;
  //   }

  //   if (!this.history.has(property.property_id)) {
  //     const url = `/api/organizations/${this.organization_id}/people/${form.person_id}/responses/${property.name}`;
  //     this.history.set(
  //       property.property_id,
  //       fetch(url).then(r => {
  //         if (r.ok) {
  //           return r.json();
  //         } else {
  //           this.history.delete(property.property_id);
  //           throw new Error(`Failed to fetch response history "${url}".`);
  //         }
  //       })
  //     );
  //   }

  //   this.historyCounter.textContent = '0';
  //   this.historyContainer.innerHTML = `<span>Loading</span><span class="AnimatedEllipsis"></span>`;
  //   const allHistory = await this.history.get(property.property_id)!;
  //   const history = allHistory.filter(x => x.form_id !== form.form_id);
  //   this.historyCounter.textContent = history.length.toString();
  //   if (history.length) {
  //     const elId = newElementId();
  //     this.historyContainer.innerHTML = render(html`
  //       <h5 id="${elId}">${property.label} history</h5>
  //       <ol class="Box Box--condensed" aria-labelledby="${elId}">
  //         ${history.map(
  //           hi => html`<li class="Box-row d-flex flex-items-center">
  //             <div class="flex-1">
  //               <span class="d-block mb-1 text-bold"
  //                 >${getPropertyDisplayValue(property, hi.value)}</span
  //               >
  //               <a
  //                 href="/${this.organization_login}/people/${uuidToBase58(
  //                   form.person_id!
  //                 )}/forms/${uuidToBase58(hi.form_id)}"
  //                 class="Link--primary f6 text-capitalize"
  //               >
  //                 <relative-time datetime="${hi.create_datetime}"
  //                   >${hi.create_datetime}</relative-time
  //                 >
  //                 in ${hi.form_design_title}</a
  //               >
  //             </div>
  //             ${property.calculated
  //               ? null
  //               : html`<button
  //                   class="btn btn-sm ml-3"
  //                   type="button"
  //                   data-action="click:activity-controller#applyHistoryValue"
  //                   form-id="${hi.form_id}"
  //                   property-id="${property.property_id}"
  //                 >
  //                   Insert
  //                 </button>`}
  //           </li>`
  //         )}
  //       </ol>
  //     `);
  //   } else {
  //     // todo: blankslate
  //     this.historyContainer.innerHTML = render(
  //       html`
  //         <h5>${property.label} history</h5>
  //         <p class="zero-state">No prior responses</p>
  //       `
  //     );
  //   }
  // }

  // async applyHistoryValue({ target }: Event) {
  //   if (!(target instanceof HTMLElement)) {
  //     throw new Error('Expected element target');
  //   }
  //   const property_id = +target.getAttribute('property-id')!;
  //   const form_id = target.getAttribute('form-id')!;
  //   const allHistory = await this.history.get(property_id);
  //   if (!allHistory) {
  //     throw new Error(`Expected history for ${property_id} to be loaded.`);
  //   }
  //   const item = allHistory.find(hi => hi.form_id === form_id);
  //   if (!item) {
  //     throw new Error(
  //       `Expected history value for form "${form_id}"  and property ${property_id} to be available.`
  //     );
  //   }

  //   const form = this.readForm();
  //   const design = getData<FormDesign>(form.form_design_id);
  //   const property = design.properties.find(p => p.property_id === property_id);
  //   if (!property) {
  //     throw new Error(`Property ${property_id} not found in form design.`);
  //   }
  //   const expressionScope = new FormExpressionScope(form, design);
  //   walkFormDesign(design, expressionScope, (el, visible) => {
  //     if (!visible || el.type !== 'field' || el.key !== property.name) {
  //       return;
  //     }
  //     const element = this.form.querySelector(`[form-element="${el.id}"]`);
  //     if (!element) {
  //       throw new Error(`Unable to find form-element with id "${el.id}".`);
  //     }
  //     setActivityElementValue(element, property, item.value, true);
  //   });
  // }
}

function setActivityElementValue(
  element: Element,
  property: Property,
  value: any
): HTMLInputElement | null {
  let changed: HTMLInputElement | null = null;
  if (
    !property.calculated &&
    property.type === 'int' &&
    property.choice_type !== 'none'
  ) {
    const inputs = element.querySelectorAll<HTMLInputElement>(
      `[name="responses/p${property.property_id}"],[name^="responses/p${property.property_id}:"]`
    );
    let checkedFn: (input: HTMLInputElement) => boolean;
    if (property.choice_type === 'multiple') {
      if (typeof value === 'number') {
        value = maskToArray(value);
      }
      if (Array.isArray(value)) {
        checkedFn = x =>
          (x.checked = value.find((v: number) => String(v) === x.value));
      } else {
        checkedFn = x => (x.checked = x.value === '');
      }
    } else {
      checkedFn = x => (x.checked = x.value === String(value));
    }
    inputs.forEach(x => {
      const checked = checkedFn(x);
      if (checked !== x.checked) {
        changed = x;
        x.checked = checked;
      }
    });
  } else {
    const input = element.querySelector<HTMLInputElement>(
      `[name="responses/p${property.property_id}"],[name^="responses/p${property.property_id}:"]`
    )!;
    if (property.type === 'date') {
      if (value) {
        value = new Date(value);
      }
      if (value instanceof Date) {
        value = userDateFormat.format(value);
      }
    }
    value = (value ?? '').toString();
    if (input.value !== value) {
      changed = input;
      input.value = value;
    }
  }
  return changed;
}

function resolveFieldLinks(form: HTMLFormElement) {
  document
    .querySelectorAll<HTMLAnchorElement>('[href^="#field:"]')
    .forEach(a => {
      const property_id = a.getAttribute('href')!.substr('#field:'.length);
      const input = form.querySelector<HTMLElement>(
        `[name="responses/p${property_id}"][id],[name^="responses/p${property_id}:"][id]`
      );
      if (!input) {
        return;
      }
      const group = input.closest<HTMLElement>('.form-group');
      if (!group) {
        return;
      }
      input.style.scrollMarginTop = `${Math.max(input.offsetTop - group.offsetTop, 60)}px`;
      a.href = `#${input.id}`;
    });

  document
    .querySelectorAll<HTMLAnchorElement>(
      '[href^="#associated:person"],[href^="#associated:person-details"],[href^="#associated:parent-activity"]'
    )
    .forEach(a => {
      const association = a.getAttribute('href')!.substr('#associated:'.length);
      switch (association) {
        case 'person':
          a.href = location.pathname.split('/').slice(0, 4).join('/');
          break;
        case 'person-details':
          a.href =
            location.pathname.split('/').slice(0, 4).join('/') + '/details';
          break;
        case 'parent-activity':
          a.href =
            document
              .getElementById('activity-parent-label')
              ?.getAttribute('href') ?? a.href;
          break;
      }
    });

  document
    .querySelectorAll<HTMLAnchorElement>('[href^="#status:"]')
    .forEach(a => {
      const status = a.getAttribute('href')!.substr('#status:'.length);
      if (!activityStatuses.some(x => x.id === status)) {
        return;
      }
      a.role = 'button';
      a.addEventListener('click', event => {
        event.preventDefault();
        const radios = form.elements.namedItem('status') as RadioNodeList;
        for (let i = radios.length - 1; i >= 0; i--) {
          const radio = radios.item(i) as HTMLInputElement;
          if (radio.value === status) {
            radio.click();
            break;
          }
        }
      });
    });
}

declare global {
  interface Window {
    ActivityControllerElement: typeof ActivityControllerElement;
  }
  interface HTMLElementTagNameMap {
    'activity-controller': ActivityControllerElement;
  }
}
